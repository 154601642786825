<template>
  <div>
    <a-card :bordered="true">
      <div class="txt-12 txt-font-din-medium mb-10">{{ $t('components.titles.graphs') }}</div>
      <a-radio-group v-model="filters.group" class="w100">
        <div class="flex justify-center">
          <div class="w50 flex flex-column">
            <a-radio value="all" class="mb-4">
              {{ $t('components.dropdown.all') }}
            </a-radio>
            <a-radio value="sex">
              {{ $t('components.dropdown.sex') }}
            </a-radio>
          </div>
          <div class="w50 flex flex-column">
            <a-radio value="cluster" class="mb-4" :disabled="isRandomOrExhaustive">
              {{ $tc('components.dropdown.cluster', 1) }}
            </a-radio>
            <a-radio value="months">
              {{ $t('components.dropdown.age') }}
            </a-radio>
          </div>
        </div>
      </a-radio-group>
      <div class="graph-seperator"></div>
      <a-radio-group v-model="filters.type" class="w100">
        <div class="flex justify-center">
          <div class="w50 flex flex-column">
            <a-radio value="whz" class="mb-4">
              {{ $t('components.dropdown.weightByHeight') }}
            </a-radio>
            <a-radio value="muac" class="mb-4">
              {{ $t('components.dropdown.muac') }}
            </a-radio>
            <!-- <a-radio value="muac_age">
              MUAC / Age
            </a-radio> -->
          </div>
          <div class="w50 flex flex-column">
            <a-radio value="waz" class="mb-4">
              {{ $t('components.dropdown.weightByAge') }}
            </a-radio>
            <a-radio value="haz" class="mb-4">
              {{ $t('components.dropdown.heightByAge') }}
            </a-radio>
            <!-- <a-radio value="bmi_age">
              BMI / Age
            </a-radio> -->
          </div>
        </div>
      </a-radio-group>
    </a-card>
    <a-card :bordered="true" class="mt-10">
      <div class="txt-12 txt-font-din-medium mb-4">{{ $t('components.titles.exclusionZScoreWith') }}</div>
      <a-radio-group v-model="filters.exclusion" class="w100"
        @change="exclusionChange($event.target.value)">
        <div class="flex justify-center">
          <div class="w50 flex flex-column">
            <a-radio value="smart_exclusions" class="mb-10">
              {{ $t('components.dropdown.smartFlags') }}
            </a-radio>
            <a-radio value="who_exclusions">
              {{ $t('components.dropdown.whoFlags') }}
            </a-radio>
          </div>
          <div class="w50 flex flex-column">
            <a-radio value="no_exclusions" class="mb-10">
              {{ $t('components.dropdown.noExclusion') }}
            </a-radio>
          </div>
        </div>
      </a-radio-group>
    </a-card>
    <a-card :bordered="true" class="mt-10">
      <div class="txt-12 txt-font-din-medium mb-4">{{ $t('components.titles.distribution') }}</div>
      <a-select
        v-model="filters.distribution"
        class="w100"
        :disabled="distributionGraphs.length === 0"
        :get-popup-container="(triggerNode) => triggerNode.parentNode">
        <a-select-option
          v-for="option in distributionGraphs"
          :key="option.value"
          :value="option.value">
          {{ option.name }}
        </a-select-option>
      </a-select>
    </a-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

const defaultFilters = {
  group: 'all',
  type: 'whz',
  exclusion: 'no_exclusions',
  distribution: 'gaussian',
  params: undefined
};

export default {
  name: 'AnthropometryResultFilters',
  props: {
    value: {
      type: Object,
      requred: true,
      default: () => defaultFilters
    },
    surveyInfo: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      filters: defaultFilters
    };
  },
  computed: {
    isRandomOrExhaustive() {
      return this.surveyInfo && this.surveyInfo.metadata && (this.surveyInfo.metadata.samplingMethod === 'Random' || this.surveyInfo.metadata.samplingMethod === 'Exhaustive');
    },
    distributionSubTypes() {
      const distributionSubTypes = {
        GaussianCurve: {
          name: this.$t('components.dropdown.gaussian'),
          value: 'gaussian'
        },
        CumulativeDistribution: {
          name: this.$t('components.dropdown.cumulativeDistribution'),
          value: 'cumulative'
        },
        ProbabilityPlot: {
          name: this.$t('components.dropdown.probabilityPlot'),
          value: 'probability'
        },
        Groups: {
          name: this.$t('components.dropdown.groups'),
          value: 'groups'
        },
        DistWHZ2: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.whzTwo') }),
          value: 'dist_whz_2'
        },
        DistWHZ3: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.whzThree') }),
          value: 'dist_whz_3'
        },
        DistEdema: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.edema') }),
          value: 'dist_edema'
        },
        DistGAM: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.gam') }),
          value: 'dist_gam'
        },
        DistSAM: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.sam') }),
          value: 'dist_sam'
        },
        DistHAZ2: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.hazTwo') }),
          value: 'dist_haz_2'
        },
        DistHAZ3: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.hazThree') }),
          value: 'dist_haz_3'
        },
        DistWAZ2: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.wazTwo') }),
          value: 'dist_waz_2'
        },
        DistWAZ3: {
          name: this.$t('components.dropdown.distributionFilter', { filter: this.$t('components.dropdown.wazThree') }),
          value: 'dist_waz_3'
        }
      };
      return distributionSubTypes;
    },
    distributionGraphs() {
      const {
        GaussianCurve,
        CumulativeDistribution,
        ProbabilityPlot,
        Groups,
        DistWHZ2,
        DistWHZ3,
        DistEdema,
        DistGAM,
        DistSAM,
        DistWAZ2,
        DistWAZ3,
        DistHAZ2,
        DistHAZ3
      } = this.distributionSubTypes;
      const distributionTypes = {
        all: {
          whz: [GaussianCurve, CumulativeDistribution, ProbabilityPlot],
          muac: [CumulativeDistribution],
          waz: [GaussianCurve, CumulativeDistribution, ProbabilityPlot],
          haz: [GaussianCurve, CumulativeDistribution, ProbabilityPlot],
          muac_age: [],
          bmi_age: []
        },
        sex: {
          whz: [GaussianCurve, CumulativeDistribution, ProbabilityPlot],
          muac: [CumulativeDistribution],
          waz: [GaussianCurve, CumulativeDistribution, ProbabilityPlot],
          haz: [GaussianCurve, CumulativeDistribution, ProbabilityPlot],
          muac_age: [],
          bmi_age: []
        },
        cluster: {
          whz: [Groups, DistWHZ2, DistWHZ3, DistEdema, DistGAM, DistSAM],
          muac: [Groups],
          waz: [Groups, DistWAZ2, DistWAZ3],
          haz: [Groups, DistHAZ2, DistHAZ3],
          muac_age: [],
          bmi_age: []
        },
        months: {
          whz: [Groups],
          muac: [Groups],
          waz: [Groups],
          haz: [Groups],
          muac_age: [Groups],
          bmi_age: [Groups]
        }
      };
      return [...distributionTypes[this.filters.group][this.filters.type]];
    }
  },
  watch: {
    distributionGraphs: {
      deep: true,
      handler(newValue) {
        if (newValue.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.filters.distribution = newValue[0].value;
        } else {
          this.filters.distribution = undefined;
        }
      }
    },
    filters: {
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function() {
        this.onChange();
      }, 500)
    }
  },
  mounted() {
    this.exclusionChange(this.filters.exclusion);
  },
  methods: {
    onChange() {
      this.$emit('input', { ...this.filters });
    },
    exclusionChange(value) {
      this.filters.params = {
        exclusions: value
      };
    }
  }
};
</script>

<style lang="scss">
</style>
